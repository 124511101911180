"use strict";
exports.__esModule = true;
exports.DEFAULT_CLUB_SETTINGS_CUSTOMER = exports.buildCleanReference = void 0;
function trim(str, replacement) {
    if (replacement === void 0) { replacement = ''; }
    return str.replace(/^\s+|\s+$/g, replacement);
}
function buildCleanReference(baseRef) {
    var ref = trim(baseRef, '-')
        .toLowerCase() // normalise (trim, lowercase...)
        .replace(/[\W_]/g, '-') // replace all non alpha-numeric
        .replace(/(-)\1+/g, '$1') // remove consecutive "-" https://stackoverflow.com/a/55636681/1097926
        .replace(/(^\W*)|(\W*$)/g, ''); // remove initial and final "-"
    return ref;
}
exports.buildCleanReference = buildCleanReference;
var general = {
    profilePhoto: true,
    externalRef: false,
    homeAddress: true,
    phoneNumber2: true,
    languageCode: true,
    size: false,
    weight: false,
    shoeSize: false,
    age: false,
    // birthDate: true, TODO ajouter la date de naissance (pour bien comprendre qu'elle est aussi affichée)
    customerOrigin: true,
    customerOriginOptions: [],
    sanitaryPass: false,
    residentType: true,
    identificationNumber: true,
    newsletter: true,
    accomodation: false,
    pickup: false,
    isCustomer: false,
    isMember: false,
    memberRef: false,
    membershipDate: false,
    isStudent: false,
    studentDescription: false
};
var diving = {
    medicalCertificateDate: true,
    medicalCertificateChecked: true,
    medicalFormChecked: false,
    medicalFormDate: false,
    insurance: false,
    nitrox: true,
    safety: true
};
var booking = {
    externalRef: false,
    agency: false
};
var equipment = {
    provider: true,
    jacket: false,
    wetsuit: false,
    fins: false,
    altSize: false,
    scubaRegulator: false,
    mask: false,
    flashLight: false,
    computer: false,
    tank: false,
    compass: false
};
exports.DEFAULT_CLUB_SETTINGS_CUSTOMER = {
    general: general,
    diving: diving,
    equipment: equipment,
    booking: booking
};
