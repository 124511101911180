import { DiveMode, DiveSessionTheme } from '@mabadive/app-common-model';

export const diveSessionThemeAttributesFormatter = {
  getDefaultAttributes,
};

function getDefaultAttributes(theme: DiveSessionTheme): {
  defaultDiveMode?: DiveMode;
  emojiId?: string;
} {
  switch (theme) {
    case 'night':
      return {
        emojiId: 'crescent_moon',
      };
    case 'shark':
      return {
        defaultDiveMode: 'snorkeling',
        emojiId: 'shark',
      };
    case 'whale':
      return {
        defaultDiveMode: 'snorkeling',
        emojiId: 'whale',
      };
    case 'dolphin':
      return {
        defaultDiveMode: 'snorkeling',
        emojiId: 'dolphin',
      };
    case 'turtle':
      return {
        defaultDiveMode: 'snorkeling',
        emojiId: 'turtle',
      };
    case 'biology':
      return {
        defaultDiveMode: undefined,
        emojiId: undefined,
      };
  }
  return {
    defaultDiveMode: undefined,
    emojiId: undefined,
  };
}
