import { DiveSessionTheme } from '@mabadive/app-common-model';

export const diveSessionThemeFormatter = {
  formatTheme,
};

function formatTheme(theme: DiveSessionTheme): string {
  switch (theme) {
    case 'night':
      return 'Nuit';
    case 'shark':
      return 'Requins';
    case 'whale':
      return 'Baleines';
    case 'dolphin':
      return 'Dauphins';
    case 'turtle':
      return 'Tortues';
    case 'biology':
      return 'Biologie';
  }
  return '';
}
