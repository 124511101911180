/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClubDiver, OnlineBookingDiver } from '@mabadive/app-common-model';
import { nameFormatter, uuidGenerator } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useAutoFill, useRedirect } from 'src/business/_core/data/hooks';
import {
  AppBreadcrumbBar,
  AppButton,
  AppHeroIcons,
  AppIcons,
  AppIconsAction,
  AppLoadableContentContainer,
  AppPageContainer,
  AppPageContentContainer,
} from 'src/business/_core/modules/layout';
import { AppToogleButtonGroupTailwind } from '../../../../lib/form';
import { fakerClient } from '../../../_core/data/app-operation';
import { ValueLabel } from '../../../club/modules/_common/form';
import { DiveCenterBookingContactPageLocalState } from '../useDiveCenterBookingContactPageLocalState.hook';
import { CustomerProductsPage_FillContactPanelForm } from './CustomerProductsPage_FillContactPanelForm';
import { CustomerProductsPage_FillContactPanel_FormModel } from './model';
import {
  CustomerProductsPage_FillContactPanelLocalState,
  useCustomerProductsPage_FillContactPanelLocalState,
} from './useCustomerProductsPage_FillContactPanelLocalState.hook';

const AUTRE_CONTACT_OPTION: ValueLabel<string, string> = {
  value: '-1',
  label: 'AUTRE CONTACT',
};

export const CustomerProductsPage_FillContactPanel = ({
  localState: parentState,
}: {
  localState: DiveCenterBookingContactPageLocalState;
}) => {
  const redirectTo = useRedirect();

  const localState: CustomerProductsPage_FillContactPanelLocalState =
    useCustomerProductsPage_FillContactPanelLocalState({
      parentState,
    });
  const { state, data, actions } = localState;

  const form = state.form;
  const currentStep = state.currentStep;
  const setCurrentStep = state.setCurrentStep;

  const {
    register,
    handleSubmit,
    watch,
    formState,
    control,
    getValues,
    setValue,
    reset,
  } = form;

  const [bookingContactIsParticipant, bookingContactDiver] = useWatch({
    control,
    name: ['bookingContact.isParticipant', 'bookingContactDiver'],
  });

  const participantsOptions: ValueLabel<string, string>[] = useMemo(() => {
    const options = (state.cart?.bookingDetails?.bookingParticipants ?? []).map(
      (p) => {
        const option: ValueLabel<string, string> = {
          value: p.diver?._id,
          label: nameFormatter.formatFullName(
            p.diver as Pick<ClubDiver, '_id' | 'firstName' | 'lastName'>,
          ),
        };
        return option;
      },
    );
    // options.push(AUTRE_CONTACT_OPTION);
    return options;
  }, [state.cart?.bookingDetails?.bookingParticipants]);

  const autoFill = useAutoFill();
  const autoFillContactForm = async () => {
    const { bookingContact, diver } =
      await fakerClient.generateOnlineBookingContact({
        clubReference: localState.state.clubReference,
      });
    if (bookingContactDiver) {
      // on ajoute juste les champs manquants
      if (!bookingContactDiver.firstName) {
        setValue('bookingContactDiver.firstName', diver.firstName, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
      if (!bookingContactDiver.lastName) {
        setValue('bookingContactDiver.lastName', diver.lastName, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
      if (!bookingContactDiver.emailAddress) {
        setValue('bookingContactDiver.emailAddress', diver.emailAddress, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
      if (!bookingContactDiver.phoneNumber) {
        setValue('bookingContactDiver.phoneNumber', diver.phoneNumber, {
          shouldDirty: true,
          shouldTouch: true,
          shouldValidate: true,
        });
      }
    } else {
      // nouveau diver
      setValue('bookingContact', bookingContact, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
      setValue('bookingContactDiver', diver, {
        shouldDirty: true,
        shouldTouch: true,
        shouldValidate: true,
      });
    }
  };
  return (
    <>
      <AppPageContainer
        className="bg-gray-50 overflow-y-auto"
        {...state.loadableContent}
      >
        <AppBreadcrumbBar
          classNameInner="max-w-6xl mx-auto"
          firstItemClassName="ml-0"
          color={'#1a7498'}
          // color={data.appUiTheme?.text?.textColor ?? '#444556'}
          items={[
            {
              icon: AppIcons.ecommerce.onlineBooking,
              label: 'Activités',
              // onClick: () => actions.showCategories(),
              className: 'hidden sm:flex',
            },
            // {
            //   label: state.selectedCategory?.name,
            //   onClick: () => actions.selectCategory(state.selectedCategory._id),
            // },
            // {
            //   label: state.selectedProduct?.name,
            // },
          ]}
        />
        <AppPageContentContainer
          paddingBottom={false}
          className="bg-gray-50 app-p-content"
        >
          <AppLoadableContentContainer {...state.loadableContent}>
            <div className="w-full max-w-6xl mx-auto">
              <div className="app-card app-p-content pb-8 grid gap-4 relative">
                <div>
                  {/* <DiveCenterBackButton
                    onClick={() => {
                      // il faudrait plutôt aller vers le panier
                      actions.showCategories();
                    }}
                  /> */}
                  <h3 className="flex-grow text-center lg:text-left px-4 text-xl font-extrabold bg-gray-600 text-white uppercase">
                    Contact réservation
                  </h3>
                </div>

                {currentStep === 'choose-contact-participant' ? (
                  <div className="flex flex-col gap-8">
                    <div className="flex flex-col gap-4">
                      <p className="text-gray-600">
                        Choisissez le contact de la réservation :
                      </p>
                      <AppToogleButtonGroupTailwind
                        customClassName={
                          'flex flex-col sm:grid sm:grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-x-4 gap-y-4'
                        }
                        optionClassName={
                          'w-full sm:max-w-[20rem] text-blue-400'
                        }
                        optionClassNameUnselected="bg-white border-gray-400 text-gray-800 hover:bg-gray-50 over:text-blue-800"
                        color="blue"
                        theme="strong"
                        items={participantsOptions}
                        // selected={AUTRE_CONTACT_OPTION.value} // on sélectionne "autre contact" pour le mettre en évidence
                        onChanges={(value) => {
                          const participant =
                            state.cart?.bookingDetails?.bookingParticipants?.find(
                              (p) => p.diver?._id === value,
                            );
                          const bookingContactDiver: OnlineBookingDiver =
                            participant?.diver;
                          setValue('bookingContactDiver', bookingContactDiver);
                          setValue('bookingContact.isParticipant', true);
                          setValue('bookingContact', {
                            diverId: bookingContactDiver?._id,
                            isParticipant: true,
                          });

                          setCurrentStep('fill-contact-form');
                        }}
                      />
                    </div>

                    <div className="flex flex-col gap-4">
                      <p className="text-gray-600">
                        Ou créez une nouvelle fiche pour le contact :
                      </p>
                      <div className="text-left">
                        <AppButton
                          className={'w-full sm:max-w-[12rem]'}
                          color="primary-outline-light"
                          onClick={() => {
                            const diverId = uuidGenerator.random();
                            setValue('bookingContactDiver', {
                              _id: diverId,
                            } as any);
                            setValue('bookingContact', {
                              diverId,
                              isParticipant: false,
                            });
                            setCurrentStep('fill-contact-form');
                          }}
                        >
                          NOUVEAU CONTACT
                        </AppButton>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    {autoFill && (
                      <div className="absolute -right-1 p-1 z-50">
                        <AppButton
                          type="button"
                          className="z-50"
                          color={'primary-outline-light'}
                          size="normal"
                          icon={AppHeroIcons.autoFill}
                          onClick={autoFillContactForm}
                        ></AppButton>
                      </div>
                    )}
                    <div className="my-2 text-base xs:text-lg font-bold text-app-primary">
                      Renseignez les coordonnées du contact
                    </div>
                    <CustomerProductsPage_FillContactPanelForm form={form} />
                    <div className={clsx('my-6 flex gap-8 justify-center')}>
                      <AppButton
                        className="uppercase"
                        size="large"
                        icon={AppIconsAction.back}
                        color="gray-outline-light"
                        onClick={() => {
                          setCurrentStep('choose-contact-participant');
                        }}
                      >
                        Retour
                      </AppButton>
                      <AppButton
                        className="uppercase"
                        size="large"
                        icon={AppHeroIcons.actionConfirm}
                        color="primary-outline-light"
                        // disabled={!form.formState.isValid}
                        onClick={() => {
                          handleSubmit(
                            (
                              formValue: CustomerProductsPage_FillContactPanel_FormModel,
                            ) => {
                              actions.confirmBookingContact(formValue);
                            },
                          )();
                        }}
                      >
                        Confirmer
                      </AppButton>
                    </div>
                  </>
                )}
              </div>
            </div>
          </AppLoadableContentContainer>
        </AppPageContentContainer>
      </AppPageContainer>
    </>
  );
};
