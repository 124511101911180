"use strict";
exports.__esModule = true;
exports.CLUB_DEFAULT_UI_DIVE_MODE = void 0;
var _core_1 = require("../_core");
exports.CLUB_DEFAULT_UI_DIVE_MODE = {
    observer: {
        ref: 'ACC',
        labelShort: 'Accompagnateur',
        labelLong: 'Accompagnateur',
        color: _core_1.APP_COLORS.diveModeObserver,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    snorkeling: {
        ref: 'PMT',
        labelShort: 'PMT / Snorkeling',
        labelLong: 'PMT / Snorkeling',
        color: _core_1.APP_COLORS.diveModeSnorkeling,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    snorkelingSupervised: {
        ref: 'RP',
        labelShort: 'Randonnée palmée',
        labelLong: 'Randonnée palmée',
        color: _core_1.APP_COLORS.diveModeSnorkelingSupervised,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    watchingTour: {
        ref: 'EXC',
        labelShort: 'Excursion',
        labelLong: "Excursion d'observation",
        color: _core_1.APP_COLORS.diveModeWatchingTour,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    'first-dive': {
        ref: 'BPT',
        labelShort: 'Baptême',
        labelLong: 'Baptême de plongée',
        color: _core_1.APP_COLORS.diveModeFirstDive,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    training: {
        ref: 'FN',
        labelShort: 'Formation',
        labelLong: 'Formation de plongée',
        color: _core_1.APP_COLORS.diveModeTraining,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 10,
            minPartFull: 10
        }
    },
    'theoretical-training': {
        ref: 'FT',
        labelShort: 'Théorie',
        labelLong: 'Formation théorique',
        color: _core_1.APP_COLORS.diveModeTheoreticalTraining,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    supervised: {
        ref: 'PE',
        labelShort: 'Encadré',
        labelLong: 'Plongeur Encadré',
        color: _core_1.APP_COLORS.diveModeSupervised,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 10,
            minPartFull: 10
        }
    },
    autonomous: {
        ref: 'PA',
        labelShort: 'Autonome',
        labelLong: 'Plongeur Autonome',
        color: _core_1.APP_COLORS.diveModeAutonomous,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 10,
            minPartFull: 10
        }
    },
    autoSupervised: {
        ref: 'AE',
        labelShort: 'Auto-encadré',
        labelLong: 'Plongeur Auto-encadré',
        color: _core_1.APP_COLORS.diveModeAutoSupervised,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    'free-dive': {
        ref: 'APN',
        labelShort: 'Apnée',
        labelLong: 'Apnée',
        color: _core_1.APP_COLORS.diveModeFreeDive,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    instructor: {
        ref: 'GP',
        labelShort: 'Guide de palanquée',
        labelLong: 'Guide de palanquée',
        color: _core_1.APP_COLORS.diveModeInstructor,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    },
    unknown: {
        ref: '???',
        labelShort: 'Inconnu',
        labelLong: 'Inconnu',
        color: _core_1.APP_COLORS.diveModeUnknown,
        shrinkGroup: {
            // vue compactée
            minPartCompact: 5,
            minPartFull: 5
        }
    }
};
