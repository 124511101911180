/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { dateService, nameFormatter } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { AppFormControlRHF, AppInputRHF } from '../../../../../../lib/form';
import {
  AppButton,
  AppHeroIcons,
  AppIconsAction,
} from '../../../../../_core/modules/layout';
import { AppPriceLabel } from '../../../../../club/modules/_common/ui';
import { CustomerProductsPage_BookProductPanelLocalState } from './useCustomerProductsPage_BookProductPanelLocalState.hook';

export const CustomerProductsPage_BookProductPanel_ConfirmProduct = ({
  localState,
  className,
}: {
  localState: CustomerProductsPage_BookProductPanelLocalState;
  className?: string;
}) => {
  const redirectTo = useRedirect();

  const { state, data, actions } = localState;
  const control = state.form.control;

  const productArticleFull = data.productArticleFull;

  const [
    activityDate,
    diveSessionDateTime,
    diveSessionReference,
    items,
    comment,
  ] = useWatch({
    control,
    name: [
      'article.activityDate',
      'article.diveSessionDateTime',
      'article.diveSessionReference',
      'article.items',
      'article.comment',
    ],
  });

  const totalPrice = useMemo(
    () =>
      (items ?? [])
        .filter((x) => x.quantity > 0)
        .reduce(
          (acc, price) => acc + price.quantity * price.price.unitPrice,
          0,
        ),
    [items],
  );

  return (
    <div className={clsx('flex flex-col items-stretch sm:flex gap-2')}>
      <div className="font-bold text-gray-800">
        <span className="uppercase">
          {dateService.formatUTC(activityDate, 'dddd DD/MM/YYYY')}
        </span>
        {diveSessionDateTime &&
          ` à ${dateService.formatUTC(diveSessionDateTime, 'HH:mm')}`}
      </div>
      {(items ?? []).map((item) => (
        <div
          key={item.price._id}
          className={clsx(
            'rounded-lg border border-gray-300 bg-gray-50 p-2 w-full grid md:flex gap-2 items-start',
          )}
        >
          <div className="w-full flex flex-col gap-2">
            <div className="">
              <div className="w-full flex flex-row gap-4 justify-between items-start">
                <div className="uppercase font-bold text-gray-600">
                  <div className="text-app-primary">
                    {productArticleFull?.product?.name}
                  </div>
                  <div className="">{item.price.label}</div>
                </div>
                <AppPriceLabel
                  className="text-gray-500 font-bold"
                  amount={item.price.unitPrice}
                  mainCurrency={state.mainCurrency}
                />
              </div>
              <div className="italic text-gray-500">{item.price.comment}</div>
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-between">
              <ul className="text-gray-600 ">
                {item.participants?.map((participant) => (
                  <li
                    key={participant.diverId}
                    className={clsx('ml-4 list-disc')}
                  >
                    {nameFormatter.formatFullName(participant)}{' '}
                    {participant?.age > 0 && ` (${participant?.age} ans)`}
                  </li>
                ))}
              </ul>
              <div>
                <div className=" text-right text-lg font-bold text-gray-600">
                  {item.quantity !== item.participants?.length ? (
                    <>
                      <div className="text-gray-600">
                        {item.participants?.length} PARTICIPANTS
                      </div>
                      <div className="text-app-primary">
                        Quantité: {item.quantity}
                      </div>
                    </>
                  ) : (
                    <>{item.quantity} PARTICIPANTS</>
                  )}
                </div>
                <div className=" text-right text-lg font-bold text-gray-600 uppercase">
                  TOTAL:{' '}
                  <AppPriceLabel
                    className="text-gray-500 font-bold"
                    amount={totalPrice}
                    mainCurrency={state.mainCurrency}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <AppFormControlRHF
        className="mt-4 w-full"
        label="Commentaire facultatif"
        control={control}
        name={'article.comment'}
        renderComponent={(props) => (
          <AppInputRHF
            {...props}
            fullWidth
            multiline
            rowsMax={15}
            rows={2}
            // placeholder="Précision, situation particulière, questions spécifiques..."
          />
        )}
      />
      <div className={clsx('my-6 flex gap-8 justify-around')}>
        <AppButton
          className="uppercase"
          size="large"
          icon={AppIconsAction.back}
          color="gray-outline-light"
          onClick={() => actions.backToPreviousStep()}
        >
          Retour
        </AppButton>
        <AppButton
          className="uppercase"
          size="large"
          icon={AppHeroIcons.actionConfirm}
          color="primary-bg"
          onClick={() => actions.confirmOrderArticle()}
        >
          Confirmer
        </AppButton>
      </div>
    </div>
  );
};
