"use strict";
exports.__esModule = true;
exports.TEMPLATE_EXPORT_SHEET_EQUIPMENT = void 0;
var equipment_1 = require("../../../../equipment");
var excel_export_1 = require("../../../../excel-export");
var sheetDiverColumnsIds = [
    'name',
    'level',
    'aptitude',
    'totalDive',
    'lastDive',
    'medicalFormFilled',
    'medicalCertificate',
    'insurance',
    'gaz',
    // 'gazMod',
    // 'gazPressure',
    // 'tankNumber',
    'jacket',
    'wetsuit',
    'fins',
    'scubaRegulator',
    'mask',
    // 'signature',
    'bookingSessionParticipantsPurchase',
];
var paramColumnsIds = [
// 'timeStart',
// 'timeEnd',
// 'timeDuration',
// 'depth',
// 'dtr',
// 'decoOptional',
// 'decoStage1',
// 'decoStage2',
// 'decoStage3',
// 'surfaceIntervalDuration',
];
var configSheetContentSessions = {
    id: 'sessions',
    // columns: 2,
    items: [
        {
            id: 'title',
            enabled: true,
            number: true,
            name: true,
            time: true
        },
        {
            id: 'participants-count',
            enabled: true,
            divers: true,
            staff: true,
            total: true
        },
        {
            id: 'comments',
            enabled: true,
            // label: 'Notes',
            importantNotes: true,
            comment: true,
            tide: true,
            weather: true
        },
        {
            id: 'equipement',
            enabled: true,
            types: equipment_1.EQUIPMENT_TYPES,
            columns: excel_export_1.EXPORT_DAILY_CONFIG_CONTENT_SESSIONS_EQUIPMENT_COLUMNS
        },
        {
            id: 'dives',
            enabled: true,
            items: [
                {
                    id: 'title',
                    number: true,
                    time: true,
                    diveSite: true,
                    boat: true
                },
                {
                    id: 'title',
                    divingDirector: true,
                    surfaceSecurity: true
                },
                {
                    id: 'dive-groups',
                    diverColumns: sheetDiverColumnsIds.map(function (x) { return ({ id: x }); }),
                    paramColumns: paramColumnsIds.map(function (x) { return ({
                        id: x,
                        firstDive: !excel_export_1.EXPORT_DAILY_GROUP_PARAM_COLUMN_HIDDEN_BY_DEFAULT_FIRST_DIVE.includes(x)
                    }); }),
                    paramsPosition: 'down',
                    addEmptyRows: {
                        maxEmptyRows: 0,
                        maxTotalRows: 0,
                        maxTotalRowsForFirstDive: 0
                    },
                    addEmptyGroups: {
                        maxEmptyGroups: 0,
                        emptyRowsPerGroup: 0,
                        maxTotalGroups: undefined
                    }
                },
            ]
        },
    ]
};
// TODO adapter dynamiquement en fonction de la config
var configSheetWidthCellsCount = 50;
var sheetTitle = 'Équipement';
exports.TEMPLATE_EXPORT_SHEET_EQUIPMENT = {
    tab: {
        name: sheetTitle,
        color: '#15a848',
        comment: 'Détails des équipements de chaque plongée'
    },
    content: {
        title: sheetTitle,
        items: [
            {
                id: 'events'
            },
            configSheetContentSessions,
        ]
    },
    settings: {
        defaultHeight: 20,
        widthCellsCount: configSheetWidthCellsCount,
        orientation: 'landscape'
    }
};
