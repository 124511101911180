import {
  ChatBubbleLeftEllipsisIcon,
  EnvelopeIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { ReactComponent as facebook } from 'src/assets/icons/social-media/facebook.svg';
import { ReactComponent as instagram } from 'src/assets/icons/social-media/instagram.svg';
import { ReactComponent as website } from 'src/assets/icons/social-media/web-globe.svg';
import { ReactComponent as whatsapp } from 'src/assets/icons/social-media/whatsapp.svg';
import { ReactComponent as whatsappColor } from 'src/assets/icons/social-media/whatsapp_color.svg';
import { ReactComponent as youtube } from 'src/assets/icons/social-media/youtube.svg';

export const AppIconsSocialMedia = {
  facebook,
  website,
  instagram,
  youtube,
  mail: EnvelopeIcon,
  sms: ChatBubbleLeftEllipsisIcon,
  whatsapp,
  whatsappColor,
  phone: PhoneIcon,
};
