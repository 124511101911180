import {
  CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE,
  ClubStaffMember,
  ClubStaffMemberDailyAvailabilities,
  ClubStaffMemberRole,
  StaffMemberDailyAvailabilityResume,
} from '@mabadive/app-common-model';
import { ClubStaffMemberDailyAvailability } from '../../../../app-common-model/src/entities/club-staff-member/ClubStaffMemberDailyAvailability.type';
import { dataSorter, dateService } from '../../data';

export const staffMemberDefaultAvailabilityFinder = {
  getDefaultDailyAvailability,
};

function getDefaultDailyAvailability(
  staffMember: Pick<ClubStaffMember, 'profile' | 'availabilities'>,
  {
    date = new Date(),
  }: {
    date: Date;
  },
): StaffMemberDailyAvailabilityResume {
  const availableRoles: ClubStaffMemberRole[] =
    staffMember.profile?.roles ?? [];

  const availabilities = staffMember.availabilities.filter(
    (x) =>
      dateService.isBefore(x.beginDate, date) &&
      (!x.endDate || dateService.isBefore(date, x.endDate)),
  );
  if (availabilities.length === 0) {
    if (staffMember.availabilities.length === 1) {
      const nextOrPreviousAvailability = staffMember.availabilities[0];
      // pour l'instant, on ne gère pas les multiples dispos, donc autant afficher les bonnes infos
      return {
        isAvailableOnPeriod: false,
        isAvailableToday: false,
        availabilityPeriod: 'none',
        availableRoles,
        jobType: nextOrPreviousAvailability?.jobType,
        defaultWorkingDays: nextOrPreviousAvailability.defaultWorkingDays,
      };
    }
    return {
      isAvailableOnPeriod: false,
      isAvailableToday: false,
      availabilityPeriod: 'none',
      availableRoles,
      jobType: undefined, // 'regular', // il faudrait peut-être mettre "undefined"
      defaultWorkingDays: {} as ClubStaffMemberDailyAvailabilities,
    };
  }
  const [firstAvailability] = dataSorter.sortMultiple(availabilities, {
    getSortAttributes: (c) => {
      return [
        {
          value: c.beginDate ? new Date(c.beginDate)?.getTime() : undefined,
          asc: false,
        },
        {
          value: c.endDate ? new Date(c.endDate).getTime() : undefined,
          asc: true,
        },
        {
          value: c._id,
        },
      ];
    },
  });

  const todayIso = dateService.getUTCWeekDayIso(date);

  const defaultDailyAvailability: ClubStaffMemberDailyAvailability =
    firstAvailability.defaultWorkingDays[todayIso];
  const isAvailableToday =
    CLUB_STAFF_MEMBER_DAILY_AVAILABILITY_PERIODS_AVAILABLE.includes(
      defaultDailyAvailability?.availabilityPeriod,
    );

  const resume: StaffMemberDailyAvailabilityResume = {
    isAvailableOnPeriod: true,
    isAvailableToday,
    availableRoles,
    jobType: firstAvailability.jobType,
    defaultWorkingDays: firstAvailability.defaultWorkingDays,
    availabilityPeriod: defaultDailyAvailability?.availabilityPeriod,
  };
  return resume;
}
