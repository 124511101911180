import {
  ClubProductPackage,
  ClubProductPackageMeta,
} from '@mabadive/app-common-model';

export const clubProductPackageMetaReader = {
  readMeta,
};

function readMeta(
  productPackage: Pick<
    ClubProductPackage,
    'diveAttributes' | 'type' | 'productAttributes' | 'trainingAttributes'
  >,
): ClubProductPackageMeta {
  if (!productPackage) {
    return undefined;
  }
  const diveMode = productPackage.diveAttributes?.diveMode;

  const isTraining = productPackage.type === 'training';
  const isPlan =
    productPackage.type === 'dive' &&
    (diveMode === 'supervised' ||
      diveMode === 'autonomous' ||
      diveMode === 'instructor');

  const isOther = !(isTraining || isPlan);

  const productDefaultCreditsCount =
    productPackage.diveAttributes?.divesCount ?? 0;

  const productDefaultTotalMultipleCount =
    (productPackage.diveAttributes?.divesCount ?? 0) *
    (productPackage.diveAttributes?.successiveDivesCount ?? 1);

  const meta: ClubProductPackageMeta = {
    isTraining,
    isOther,
    isPlan,
    type: productPackage.type,
    productAttributes: productPackage.productAttributes,
    diveAttributes: productPackage.diveAttributes,
    trainingAttributes: productPackage.trainingAttributes,
    diveMode,
    productDefaultCreditsCount,
    productDefaultTotalMultipleCount,
  };
  return meta;
}
