export const CLUB_DIVER_FULL_GRAPHQL_RETURN_ATTRIBUTES = `
_id
_createdAt
_updatedAt
_version
clubReference
firstName
lastName
fakeName
mainCertificationReference
mainNitroxCertificationReference
orgsMembers
bookingDeposits
divingCertification1
divingCertification2
phoneNumber
phoneNumber2
emailAddress
birthdate
emergencyContact
medicalCertificateDate
medicalCertificateChecked
size
weight
homeAddress
customerOrigin
sanitaryPass
residentType
divesMeta {
  lastDiveTime
  lastUpdatedDiveTime
  nextDiveTime
}
details
languageCode
`;
