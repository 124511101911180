/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppInquiryFullGql_Customer,
  AppInquiryQuestion,
} from '@mabadive/app-common-model';
import { dateService } from '@mabadive/app-common-services';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import {
  AppFormControlRHF,
  AppInputBooleanSwitchRHF,
  AppInputRHF,
  AppToogleButtonGroupRHF,
} from '../../../../../lib/form';
import { AppInputDatePickerMaterialRHF } from '../../../../../stories/components/04-form';
import { AppIconsInquiry } from '../../../../_core/modules/layout';
import { YES_NO_OPTIONS } from '../../../components';
import { DiveCenterBookingInquiryResponsePage_FormModel } from '../model';
import { DiveCenterBookingInquiryResponsePageLocalState } from '../useDiveCenterBookingInquiryResponsePageLocalState.hook';
const phoneRegex = /^(\+\d{1,3}[- ]?)?\(?\d{1,4}\)?[- ]?\d{1,4}[- ]?\d{1,9}$/;
export const DiveCenterBookingInquiryResponsePage_FormQuestion = ({
  inquiry,
  sectionIndex,
  question,
  questionIndex,
  localState,
  form,
  className,
}: {
  inquiry: AppInquiryFullGql_Customer;
  sectionIndex: number;
  question: AppInquiryQuestion;
  questionIndex: number;
  localState: DiveCenterBookingInquiryResponsePageLocalState;
  form: UseFormReturn<DiveCenterBookingInquiryResponsePage_FormModel>;
  className?: string;
}) => {
  const { state, data } = localState;
  const control = state.form.control;

  const inquiryType = inquiry.type;
  const InquiryIcon = AppIconsInquiry[inquiryType];

  const title = question.description?.main?.title;
  const subTitle = question.description?.main?.subTitle;
  const answerDetails = question.answerDetails;
  const format = answerDetails.format;
  const formatOptions = answerDetails?.formatOptions;

  const todayUTC = useMemo(
    () => dateService.getUTCDateWithoutTime(new Date()),
    [],
  );

  const baseFormName =
    `responseDetails.sections.${sectionIndex}.questions.${questionIndex}` as const;

  return (
    <div
      className={clsx('app-card app-p-content grid gap-2', className)}
      style={{
        borderColor: inquiry.settings.color,
      }}
    >
      <div className="grid gap-1">
        {format === 'agreement' && (
          <AppFormControlRHF
            control={control}
            name={`${baseFormName}.answer.bool`}
            required={answerDetails.required}
            renderComponent={(props) => (
              <div className="flex gap-3 items-end">
                <AppInputBooleanSwitchRHF {...props} />
                {title?.length > 0 && (
                  <div
                    className="text-base font-medium text-gray-800"
                    // style={{
                    //   color: inquiry.settings.color,
                    // }}
                  >
                    {title}
                    {answerDetails.required ? (
                      <span className="text-red-400">*</span>
                    ) : null}
                  </div>
                )}
              </div>
            )}
          />
        )}
        {format !== 'agreement' && title?.length > 0 && (
          <div
            className="text-base font-medium text-gray-800"
            // style={{
            //   color: inquiry.settings.color,
            // }}
          >
            {title}
            {answerDetails.required ? (
              <span className="text-red-400">*</span>
            ) : null}
          </div>
        )}
        {subTitle?.length > 0 && (
          <div className="text-sm font-medium text-gray-600">{subTitle}</div>
        )}
      </div>
      {format === 'yes-no' && (
        <AppFormControlRHF
          className="max-w-96"
          control={control}
          name={`${baseFormName}.answer.bool`}
          // required={answerDetails.required} bug: si on répond false ça sort une erreur
          validation={{
            rules: {
              validate: answerDetails.required
                ? (value) => value === true || value === false
                : undefined,
            },
          }}
          renderComponent={(props) => (
            <AppToogleButtonGroupRHF
              {...props}
              color="boolean"
              theme="strong"
              items={YES_NO_OPTIONS}
              // selected={selected}
              // onChanges={onChange}
            />
          )}
        />
      )}

      {format === 'text-short' && (
        <AppFormControlRHF
          className="w-full"
          control={control}
          name={`${baseFormName}.answer.text`}
          required={answerDetails.required}
          validation={{
            email: formatOptions?.textShort?.format === 'email',
            pattern:
              formatOptions?.textShort?.format === 'phone'
                ? phoneRegex
                : undefined,
          }}
          renderComponent={(props) => (
            <AppInputRHF fullWidth={true} {...props} />
          )}
        />
      )}
      {format === 'text-long' && (
        <AppFormControlRHF
          className="w-full"
          control={control}
          name={`${baseFormName}.answer.text`}
          required={answerDetails.required}
          renderComponent={(props) => (
            <AppInputRHF
              fullWidth={true}
              {...props}
              multiline
              rowsMax={15}
              rows={2}
            />
          )}
        />
      )}
      {format === 'date' && (
        <AppFormControlRHF
          className="w-full max-w-48"
          control={control}
          name={`${baseFormName}.answer.date`}
          required={answerDetails.required}
          validation={{
            email: formatOptions?.textShort?.format === 'email',
            rules:
              formatOptions?.textShort?.format === 'phone'
                ? {
                    pattern: phoneRegex,
                  }
                : undefined,
          }}
          renderComponent={(props) => (
            <AppInputDatePickerMaterialRHF
              className="w-full"
              initialView={
                formatOptions?.date?.type === 'birthdate' ? 'years' : 'weeks'
              }
              minDate={
                formatOptions?.date?.minDate ??
                (formatOptions?.date?.type === 'future' ? todayUTC : undefined)
              }
              maxDate={
                formatOptions?.date?.maxDate ??
                (formatOptions?.date?.type === 'birthdate' ||
                formatOptions?.date?.type === 'past'
                  ? todayUTC
                  : undefined)
              }
              {...props}
            />
          )}
        />
      )}
    </div>
  );
};
