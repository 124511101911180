import {
  BookingMember,
  BookingParticipantEditorFormModelClubParticipant,
  BookingParticipantEditorFormModelClubParticipantBookingStatus,
  BookingProduct,
  BookingProductAttributesDive,
  BookingSessionParticipant,
  ClubDiver,
  ClubParticipant,
  ClubParticipantDetails,
  DIVE_CERTIFICATIONS_REFERENCE_MAP,
  DiveCenterResume,
  DiveMode,
  FirstDiveTrainingReference,
  MultipleDiveSessionNumber,
  ParticipantActionDescription,
  ParticipantBookingStatus,
} from '@mabadive/app-common-model';
import { arrayBuilder, commonEntityBuilder } from '../../data';
import { diveModeAnalyser } from '../dive';

export const commonParticipantBuilder = {
  buildParticipantAttributesFromCurrentParticipant,
  buildParticipantAttributesFromCurrentParticipantCloneOrMove,
  buildParticipantAttributesFromDefaultDiveConfig,
  buildProductAttributesFromParticipant,
  createParticipantFromDefaultDiveConfig,
  buildBookingSessionParticipant,
  buildBookingProduct,
};
export type DiverBookingUpdateProductParticipant = {
  bookingProduct: BookingProduct;
  bookingSessionParticipant: BookingSessionParticipant;
  clubParticipant: ClubParticipant;
};

function createParticipantFromDefaultDiveConfig({
  diveCenterResume,
  bookingSessionId,
  diveSessionReference,
  defaultDiveMode,
  bookingMember,
  diver,
  bookingId,
  diveSessionGroupId,
  sessionsCount,
}: {
  diveCenterResume: Pick<
    DiveCenterResume,
    'clubReference' | '_id' | 'publicSettings'
  >;
  bookingSessionId: string;
  diveSessionReference: string;
  defaultDiveMode: DiveMode;
  bookingMember: BookingMember;
  diver: ClubDiver;
  bookingId: string;
  diveSessionGroupId?: string;
  sessionsCount: number;
}): DiverBookingUpdateProductParticipant {
  const clubReference = diveCenterResume?.clubReference;
  const diveCenterId = diveCenterResume?._id;

  const now = new Date();

  const bookingMemberId = bookingMember._id;
  const diverId = diver._id;

  const participantBookingStatus: ParticipantBookingStatus = 'confirmed';
  const clubParticipant = commonEntityBuilder.buildEntity<ClubParticipant>(
    {
      ...commonParticipantBuilder.buildParticipantAttributesFromDefaultDiveConfig(
        {
          diver,
          defaultDiveMode,
          diveCenterResume,
          sessionsCount,
        },
      ),
      clubReference,
      diveCenterId,
      diveSessionReference,
      creationMode: 'app',
      diverId,
      // copy attributes from source participant
      bookingState: {
        date: new Date(),
        value: participantBookingStatus,
      },
      bookingHistory: [],
      diveSessionGroupId,
    },
    now,
  );

  const bookingSessionParticipant = buildBookingSessionParticipant({
    clubReference,
    diveCenterId,
    bookingId,
    bookingSessionId,
    diveSessionReference,
    bookingMemberId,
    clubParticipant,
    diverId,
    now,
    participantBookingStatus,
  });

  const bookingProduct = buildBookingProduct({
    clubReference,
    diveCenterId,
    bookingId,
    bookingMemberId,
    bookingSessionId,
    bookingSessionParticipant,
    now,
    clubParticipant,
  });
  return {
    clubParticipant,
    bookingSessionParticipant,
    bookingProduct,
  };
}
function buildParticipantAttributesFromCurrentParticipant({
  participant,
  sessionsCount,
}: {
  participant: ClubParticipant;
  sessionsCount: number;
}): BookingParticipantEditorFormModelClubParticipant {
  const bookingState = participant.bookingState;
  const bookingStateStatus = bookingState && bookingState.value;
  const bookingStateUser = bookingState && bookingState.user;

  let bookingStatus: BookingParticipantEditorFormModelClubParticipantBookingStatus;
  if (bookingStateStatus === 'pending' && bookingStateUser === 'club') {
    bookingStatus = 'pending-club';
  } else if (bookingStateStatus === 'pending' && bookingStateUser === 'diver') {
    bookingStatus = 'pending-diver';
  } else if (
    bookingStateStatus === 'cancelled' &&
    bookingStateUser === 'club'
  ) {
    bookingStatus = 'cancelled-club';
  } else if (
    bookingStateStatus === 'cancelled' &&
    bookingStateUser === 'diver'
  ) {
    bookingStatus = 'cancelled-diver';
  } else if (bookingStateStatus === 'confirmed') {
    bookingStatus = 'confirmed';
  }
  const bookingComment = bookingState ? bookingState.comment : undefined;

  const clubParticipant: BookingParticipantEditorFormModelClubParticipant = {
    // participant
    // diveTypeReference: !(participant.diveMode) ? undefined : diveTypeReferenceParser.buildDiveTypeReference({
    //   diveMode: participant.diveMode,
    //   trainingReference: participant.trainingReference,
    //   certificationReference: participant.certificationReference,
    // }),
    firstDiveReference: participant.firstDiveReference,
    trainingReference: participant.trainingReference,
    certificationReference: participant.certificationReference,
    certificationDeepDiver: participant.certificationDeepDiver,
    autoSupervisedDetails: participant.autoSupervisedDetails,
    equipment: participant.equipment,
    details: participant.details,
    targetDeep: participant.targetDeep,
    gaz: participant.gaz,
    diveMode: participant.diveMode,
    aptitude: participant.aptitude,
    tags: participant.tags ?? [],
    comment: participant.comment,
    diveSessionGroupId: participant.diveSessionGroupId,
    bookingStatus,
    bookingComment,
  };
  fixMultiSessionsPresenceNumbers({ sessionsCount, clubParticipant });

  return clubParticipant;
}

function buildParticipantAttributesFromCurrentParticipantCloneOrMove({
  mode,
  sourceClubParticipant,
  targetDiveSessionReference,
  targetSessionsCount,
  targetDiveSessionGroupId,
  targetDiveSessionGroupDiveGuide,
  clubReference,
  diveCenterId,
  diverId,
  bookingComment,
}: {
  mode: 'clone' | 'move';
  sourceClubParticipant: Partial<ClubParticipant>;
  targetDiveSessionReference: string;
  targetDiveSessionGroupId: string;
  targetDiveSessionGroupDiveGuide: boolean;
  targetSessionsCount: MultipleDiveSessionNumber;
  clubReference: string;
  diveCenterId: string;
  diverId: string;
  bookingComment?: string;
}): ClubParticipant {
  if (targetSessionsCount === undefined) {
    console.error('Error cloneParticipant: sessionsCount not set');
    targetSessionsCount = 1;
  }

  const now = new Date();

  const details: ClubParticipantDetails = sourceClubParticipant.details ?? {};

  details.multiSessionsPresenceNumbers =
    targetSessionsCount > 1
      ? sourceClubParticipant.details?.multiSessionsPresenceNumbers
      : undefined;

  const divesCount = (sourceClubParticipant.details
    ?.multiSessionsPresenceNumbers?.length ??
    targetSessionsCount) as MultipleDiveSessionNumber;

  const clubParticipantAttrs: Partial<ClubParticipant> = {
    // copy attributes from source participant
    ...sourceClubParticipant,
    _id: null,
    clubReference,
    diveCenterId,
    diveSessionReference: targetDiveSessionReference,
    diveSessionGroupId: targetDiveSessionGroupId,
    diveSessionGroupDiveGuide: targetDiveSessionGroupDiveGuide,
    creationMode: 'app',
    diverId,
    divesCount,
    details,
  };
  if (mode === 'clone') {
    const participantBookingStatus: ParticipantBookingStatus = 'confirmed';
    clubParticipantAttrs.bookingState = {
      date: new Date(),
      value: participantBookingStatus,
    };
    clubParticipantAttrs.bookingHistory = [];
    clubParticipantAttrs.paymentStatus = null;
    clubParticipantAttrs.paymentComment = null;
    clubParticipantAttrs.activePurchasedPackage = null;
    clubParticipantAttrs.activePurchasedTraining = null;
    clubParticipantAttrs._createdAt = null;
    clubParticipantAttrs._updatedAt = null;
  } else if (mode === 'move') {
    const bookingState: ParticipantActionDescription<ParticipantBookingStatus> =
      {
        ...sourceClubParticipant.bookingState,
        comment: bookingComment,
        value:
          sourceClubParticipant.bookingState.value === 'cancelled'
            ? 'confirmed'
            : sourceClubParticipant.bookingState.value,
        date: new Date(),
      };
    clubParticipantAttrs.bookingHistory = [
      ...sourceClubParticipant.bookingHistory,
      clubParticipantAttrs.bookingState,
    ];
    clubParticipantAttrs.bookingState = bookingState;
  }
  const clubParticipant = commonEntityBuilder.buildEntity<ClubParticipant>(
    clubParticipantAttrs,
    now,
  );
  return clubParticipant;
}

function buildParticipantAttributesFromDefaultDiveConfig({
  sessionsCount,
  diver,
  defaultDiveMode,
  diveCenterResume,
}: {
  sessionsCount: number;
  diver: ClubDiver;
  defaultDiveMode: DiveMode;
  diveCenterResume: Pick<
    DiveCenterResume,
    'clubReference' | '_id' | 'publicSettings'
  >;
}): BookingParticipantEditorFormModelClubParticipant {
  if (!diver) {
    return {
      diveMode: defaultDiveMode,
    };
  }
  const defaultDiveConfig = diver?.defaultDiveConfig;
  if (
    diver.mainCertificationReference ||
    defaultDiveConfig ||
    defaultDiveMode
  ) {
    const diveMode = defaultDiveMode ?? buildDiveModeFromDiver(diver);

    if (!diveMode) {
      return {
        bookingStatus: 'confirmed',
        tags: [],
        diveMode: undefined,
      };
    }

    const firstDiveReference =
      defaultDiveConfig?.firstDiveReference ??
      (diveMode === 'first-dive'
        ? ('BPT' as FirstDiveTrainingReference)
        : undefined);
    const trainingReference = defaultDiveConfig?.trainingReference;
    const autoSupervisedDetails = defaultDiveConfig?.autoSupervisedDetails;
    const certificationReference =
      defaultDiveConfig?.certificationReference ??
      diver.divingCertification1?.mainCertificationReference;

    if (diveMode) {
      const p: BookingParticipantEditorFormModelClubParticipant = {
        bookingStatus: 'confirmed',
        tags: [],
        diveMode,
        certificationReference,
        certificationDeepDiver:
          diver.divingCertification1?.specialties?.deepDiver,
        firstDiveReference,
        trainingReference,
        autoSupervisedDetails,
      };
      const isScubaDivingEquipmentRequired =
        diveModeAnalyser.hasScubaDivingEquipments(diveMode);
      if (isScubaDivingEquipmentRequired) {
        if (defaultDiveConfig?.equipment) {
          p.equipment = defaultDiveConfig?.equipment;
        }
        if (defaultDiveConfig?.gaz) {
          p.gaz = defaultDiveConfig?.gaz;
        }
        if (defaultDiveConfig?.certificationDeepDiver) {
          p.certificationDeepDiver = defaultDiveConfig?.certificationDeepDiver;
        }
        if (defaultDiveConfig?.aptitude) {
          p.aptitude = defaultDiveConfig?.aptitude;
        }
        if (defaultDiveConfig?.targetDeep) {
          p.targetDeep = defaultDiveConfig?.targetDeep;
        }
        const defaultGaz = diveCenterResume?.publicSettings?.diving?.defaultGaz;
        if (
          defaultGaz?.gazType ||
          defaultGaz?.gazQuantity ||
          defaultGaz?.gazDescription
        ) {
          p.gaz = defaultGaz;
        }
      }
      fixMultiSessionsPresenceNumbers({ sessionsCount, clubParticipant: p });

      if (!p.equipment) {
        p.equipment = {};
      }
      return p;
    }
  }

  const participant: BookingParticipantEditorFormModelClubParticipant = {
    bookingStatus: 'confirmed',
    tags: [],
    diveMode: undefined,
  };
  return participant;
}
function buildDiveModeFromDiver(diver: ClubDiver): DiveMode {
  if (diver.defaultDiveConfig?.diveMode) {
    return diver.defaultDiveConfig?.diveMode;
  }
  if (!diver.mainCertificationReference) {
    return;
  }
  const certification =
    DIVE_CERTIFICATIONS_REFERENCE_MAP[diver.mainCertificationReference];
  if (!certification) {
    console.error(
      '[participantEditorFormInitialValueBuilder.buildDiveModeFromDiver] Certification not found:',
      diver.mainCertificationReference,
    );
    return undefined;
  }
  if (
    certification.reference === 'DEB' &&
    diver.clubReference === 'seacretdive-port-louis-98825'
  ) {
    return 'supervised';
  }

  if (
    diver.clubReference === 'algajola-sport-et-nature-algajola-34164' &&
    certification?.defaultDiveMode === 'autonomous'
  ) {
    return 'supervised';
  }

  return certification?.defaultDiveMode;
}

function buildBookingProduct({
  clubReference,
  diveCenterId,
  bookingId,
  bookingMemberId,
  bookingSessionId,
  bookingSessionParticipant,
  clubParticipant,
  now,
}: {
  clubReference: string;
  diveCenterId: string;
  bookingId: string;
  bookingMemberId: string;
  bookingSessionId: string;
  bookingSessionParticipant: BookingSessionParticipant;
  clubParticipant: ClubParticipant;
  now: Date;
}) {
  return commonEntityBuilder.buildEntity<BookingProduct>(
    {
      clubReference,
      diveCenterId,
      bookingId,
      bookingMemberId,
      bookingSessionId,
      bookingSessionParticipantId: bookingSessionParticipant._id,
      diveSessionReference: bookingSessionParticipant.diveSessionReference,
      participantId: bookingSessionParticipant.participantId,
      diverId: bookingSessionParticipant.diverId,

      bookingDate: now,
      bookingLastUpdateDate: now,

      type: 'dive',

      attributes: buildProductAttributesFromParticipant(clubParticipant),

      bookingProductStatus: 'confirmed',
      bookingProductState: {
        date: now,
        value: 'confirmed',
      },
      bookingProductHistory: [],
      purchaseStatus: 'pending',
    },
    now,
  );
}

function buildProductAttributesFromParticipant(
  participant: Pick<
    ClubParticipant,
    'diveMode' | 'trainingReference' | 'equipment'
  >,
): BookingProductAttributesDive {
  // attention, fonction dupliquée migration et web client
  return {
    diveMode: participant.diveMode,
    trainingReference: participant.trainingReference as any,
    hasSupervision:
      participant.diveMode === 'training' ||
      participant.diveMode === 'supervised' ||
      participant.diveMode === 'first-dive' ||
      participant.diveMode === 'free-dive' ||
      participant.diveMode === 'theoretical-training',
    equipment: participant?.equipment,
  };
}

function buildBookingSessionParticipant({
  clubReference,
  diveCenterId,
  bookingId,
  bookingSessionId,
  diveSessionReference,
  bookingMemberId,
  clubParticipant,
  diverId,
  now,
  participantBookingStatus,
}: {
  clubReference: string;
  diveCenterId: string;
  bookingId: string;
  bookingSessionId: string;
  diveSessionReference: string;
  bookingMemberId: string;
  clubParticipant: ClubParticipant;
  diverId: string;
  now: Date;
  participantBookingStatus: ParticipantBookingStatus;
}) {
  return commonEntityBuilder.buildEntity<BookingSessionParticipant>(
    {
      clubReference,
      diveCenterId,
      bookingId,
      bookingSessionId,
      bookingMemberId: bookingMemberId,
      participantId: clubParticipant._id,
      diveSessionReference,
      diverId,
      // bookingState: // TODO va remplacer clubParticipant.bookingState
      // paymentState: // TODO
      participantBookingStatus,
    },
    now,
  );
}

function fixMultiSessionsPresenceNumbers({
  sessionsCount,
  clubParticipant,
}: {
  sessionsCount: number;
  clubParticipant: Partial<Pick<ClubParticipant, 'details' | 'divesCount'>>;
}) {
  const sessionNumbers: MultipleDiveSessionNumber[] = arrayBuilder.buildSeries({
    count: sessionsCount,
    first: 1,
  });

  if (sessionsCount > 1) {
    clubParticipant.details = clubParticipant.details ?? {};
    clubParticipant.details.multiSessionsPresenceNumbers =
      clubParticipant.details.multiSessionsPresenceNumbers ?? sessionNumbers;
  } else if (clubParticipant?.details?.multiSessionsPresenceNumbers) {
    clubParticipant.details.multiSessionsPresenceNumbers = undefined;
  }
  clubParticipant.divesCount = (clubParticipant.details
    ?.multiSessionsPresenceNumbers?.length ??
    sessionsCount) as MultipleDiveSessionNumber;
}
