"use strict";
exports.__esModule = true;
exports.ORG_GROUP_FRENCH_DATA_TRAININGS = void 0;
exports.ORG_GROUP_FRENCH_DATA_TRAININGS = [
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'beginner',
        specialityType: 'main',
        reference: 'FN1',
        label: 'Formation N1',
        certificationReference: 'N1',
        maxDepth: 20,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['20m'],
        defaultAptitude: 'PE20'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FN2',
        label: 'Formation N2',
        certificationReference: 'N2',
        maxDepth: 40,
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['20m', '40m'],
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FN3',
        label: 'Formation N3',
        certificationReference: 'N3',
        maxDepth: 60,
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE60'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FN4',
        label: 'Formation N4',
        certificationReference: 'N4',
        maxDepth: 40,
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE60'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'discover',
        specialityType: 'discover',
        reference: 'F-INIT',
        label: 'Formation Initiation',
        certificationReference: undefined,
        maxDepth: 12,
        trainingDivesTotalCount: 3,
        compatibilityGroups: ['12m'],
        defaultAptitude: undefined
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'beginner',
        specialityType: 'main',
        reference: 'FPE12',
        label: 'Formation PE 12',
        certificationReference: 'PE12',
        maxDepth: 12,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['12m'],
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'beginner',
        specialityType: 'main',
        reference: 'FPA12',
        label: 'Formation PA 12',
        certificationReference: 'PA12',
        maxDepth: 12,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['12m'],
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FPA20',
        label: 'Formation PA 20',
        certificationReference: 'PA20',
        maxDepth: 20,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['20m'],
        defaultAptitude: 'PE20'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FPE40',
        label: 'Formation PE 40',
        certificationReference: 'PE40',
        maxDepth: 40,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FPA40',
        label: 'Formation PA 40',
        certificationReference: 'PA40',
        maxDepth: 40,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FPE60',
        label: 'Formation PE 60',
        certificationReference: 'PE60',
        maxDepth: 60,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE40'
    },
    ,
    {
        diveMode: 'training',
        trainingType: 'gaz-certification',
        commercialCategoryId: 'tek',
        specialityType: 'gas_blending',
        reference: 'FNXS',
        label: 'Formation Nitrox simple',
        certificationReference: 'NXS',
        specialityReference: 'fr-nxs',
        nitrox: 'simple',
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['nx']
    },
    {
        diveMode: 'training',
        trainingType: 'gaz-certification',
        commercialCategoryId: 'tek',
        specialityType: 'gas_blending',
        reference: 'FNXC',
        label: 'Formation Nitrox confirmé',
        specialityReference: 'fr-nxc',
        certificationReference: 'NXC',
        nitrox: 'advanced',
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['nx']
    },
    {
        diveMode: 'training',
        trainingType: 'gaz-certification',
        commercialCategoryId: 'tek',
        specialityType: 'gas_blending',
        reference: 'FNXT',
        label: 'Formation Trimix',
        specialityReference: 'fr-nxt',
        certificationReference: 'NXT',
        nitrox: 'trimix',
        trainingDivesTotalCount: 4,
        compatibilityGroups: ['nx']
    },
    {
        diveMode: 'training',
        trainingType: 'equipment-certification',
        commercialCategoryId: 'specialty',
        specialityType: 'equipment',
        reference: 'FNET',
        label: 'Formation étanche',
        specialityReference: 'fr-eta',
        certificationReference: 'ETA',
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['eq']
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'tek',
        specialityType: 'technical',
        reference: 'FTEC',
        label: 'Formation technique',
        maxDepth: 40,
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['40m'],
        defaultAptitude: undefined
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'tek',
        specialityType: 'technical',
        reference: 'F-CCR',
        label: 'Formation recycleur',
        maxDepth: 100,
        trainingDivesTotalCount: 20,
        compatibilityGroups: ['100m'],
        defaultAptitude: undefined
    },
    {
        diveMode: 'training',
        trainingType: 'security-certification',
        commercialCategoryId: 'security',
        specialityType: 'safety',
        reference: 'FRIFA',
        label: 'Formation RIFA',
        specialityReference: 'fr-rifa',
        trainingDivesTotalCount: 0,
        compatibilityGroups: ['sec']
    },
    {
        diveMode: 'training',
        trainingType: 'child',
        commercialCategoryId: 'discover',
        specialityType: 'child',
        reference: 'FJPB',
        label: 'Formation Jeune Plongeur de Bronze',
        certificationReference: 'JPB',
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['6m'],
        maxDepth: 6,
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'child',
        commercialCategoryId: 'discover',
        specialityType: 'child',
        reference: 'FJPA',
        label: "Formation Jeune Plongeur d'Argent",
        certificationReference: 'JPA',
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['6m'],
        maxDepth: 6,
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'child',
        commercialCategoryId: 'discover',
        specialityType: 'child',
        reference: 'FJPO',
        label: "Formation Jeune Plongeur d'Or",
        certificationReference: 'JPO',
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['12m'],
        maxDepth: 12,
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'instructor',
        specialityType: 'instructor',
        reference: 'FE1',
        label: "Formation E1",
        certificationReference: 'E1',
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['12m'],
        maxDepth: 60,
        defaultAptitude: 'PE60'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'instructor',
        specialityType: 'instructor',
        reference: 'FE2',
        label: "Formation E2",
        certificationReference: 'E2',
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['12m'],
        maxDepth: 60,
        defaultAptitude: 'PE60'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'instructor',
        specialityType: 'instructor',
        reference: 'FE3',
        label: "Formation E3",
        certificationReference: 'E3',
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['12m'],
        maxDepth: 60,
        defaultAptitude: 'PE60'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'instructor',
        specialityType: 'instructor',
        reference: 'FE4',
        label: "Formation E4",
        certificationReference: 'E4',
        trainingDivesTotalCount: 10,
        compatibilityGroups: ['12m'],
        maxDepth: 60,
        defaultAptitude: 'PE60'
    },
];
