import {
  EquipmentDescription,
  EquipmentType,
} from '@mabadive/app-common-model';
import { SortableAttribute } from '../../data/sorter/SortableAttribute.type';

export const equipmentDescriptionSorter = {
  getSortAttributes,
  getEquipmentTypeSortIndex,
};

function getSortAttributes(
  equipment: EquipmentDescription,
  orderedSizes: string[],
): SortableAttribute[] {
  return [
    {
      value: !equipment ? null : equipment.selfEquipped ? 2 : 1,
    },
    {
      // then sort by model1?.ref
      value: !equipment ? null : orderedSizes.indexOf(equipment.model1?.ref),
    },
    {
      // then sort by model2?.ref
      value: !equipment ? null : orderedSizes.indexOf(equipment.model2?.ref),
    },
  ];
}

function getEquipmentTypeSortIndex(type: EquipmentType): number {
  switch (type) {
    case 'jacket':
      return 1;
    case 'wetsuit':
      return 2;
    case 'fins':
      return 3;
    case 'scubaRegulator':
      return 4;
    case 'mask':
      return 5;
    case 'flashLight':
      return 6;
    case 'computer':
      return 7;
    case 'tank':
      return 8;
    case 'compass':
      return 9;
    case 'weighting':
      return 10;
    case 'rebreather':
      return 11;
    case 'underwaterScooter':
      return 12;
    default:
      return type;
  }
}
