"use strict";
exports.__esModule = true;
exports.DEFAULT_PROFILE_DIVE_CENTER_MANAGER = void 0;
exports.DEFAULT_PROFILE_DIVE_CENTER_MANAGER = {
    label: 'Gérant / chef de base',
    authorizations: {
        // NOUVELLE HIERARCHIE: inspirée de anim-live AuthUserCompanyAuthorizations
        staff: {
            view: {
                staffPlanning: true,
                staffSettingsDetails: true,
                staffSettingsList: true
            },
            action: {
                editStaffSheet: true,
                editPlanningDefault: true,
                editPlanningDaily: true,
                editPlanningSession: true
            }
        },
        creditNote: {
            // avoirs
            view: {
                bookingDetails: true,
                paymentsList: true
            },
            action: {
                create: true,
                edit: true,
                "delete": true
            }
        },
        communication: {
            action: {
                sendSessionMessage: true,
                sendSessionMessageEmail: true,
                sendSessionMessageSms: true,
                sendSessionMessageWhatsApp: true,
                sendMassMailing: true,
                sendBookingMessage: true,
                sendBookingMessageEmail: true,
                sendBookingMessageSms: true,
                sendBookingMessageWhatsApp: true
            }
        },
        // DEPRECATED: ancienne hiérarchie
        view: {
            planning: {
                participant: true,
                event: true
            },
            participant: {
                diver: true,
                history: true,
                booking: true,
                billing: true,
                payment: true
            },
            dashboard: {
                dailyCash: true,
                dailyPlanningResume: true,
                dailyStats: true,
                stats: true
            },
            lists: {
                diver: true,
                participant: true,
                billing: true,
                payment: true
            },
            settings: {
                offers: true,
                userAccounts: false,
                global: false,
                diveCenter: true,
                mabadiveBilling: true
            }
        },
        edit: {
            participant: {
                diver: true,
                booking: true,
                billing: true,
                billingCreate: true,
                billingEdit: true,
                billingDelete: true,
                payment: true,
                paymentCreate: true,
                paymentDelete: true,
                paymentEdit: true,
                paymentOnlineCapture: true
            },
            planning: {
                session: true,
                event: true,
                securitySheet: true
            },
            settings: {
                offers: true,
                userAccounts: false,
                global: false,
                diveCenter: true,
                accountProfiles: false,
                general: false,
                onlinePayment: false,
                accountProfilesImpersonate: false,
                archiveData: false,
                accomodation: true,
                agencies: true,
                equipment: true,
                customerSpace: false,
                exportDaily: true,
                inquiry: true
            }
        },
        download: {
            dailyCash: true,
            securitySheet: true,
            dailyResume: true,
            bookings: true,
            divers: true
        },
        multiDiveCenters: {
            switchCenter: false,
            view: {
                planning: false,
                participant: false,
                dashboard: false,
                lists: false,
                settings: false
            },
            edit: {
                participant: false,
                planning: false,
                settings: false
            }
        }
    }
};
