import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { objectMapBuilder } from '../../data';
import {
  LoadableAttribute,
  LoadableAttributeStore,
  loadableAttributeStoreFactory,
} from '../attribute';
import { SimpleStore } from '../core/SimpleStore.model';
import { LoadableAttributeCollectionStoreGetOptions } from './LoadableAttributeCollectionOptions.types';
import { LoadableAttributeCollectionStore } from './LoadableAttributeCollectionStore.model';

function getOne<T>({
  loadableAttributeStore,
  options,
}: {
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  options: LoadableAttributeCollectionStoreGetOptions<T>;
}): Observable<T> {
  return loadableAttributeStore.get().pipe(
    map((items) => {
      const { criteria } = options as {
        criteria?: Partial<T>;
      };
      if (criteria) {
        return items.find((item) => identifyByCriteria<T>(criteria, item));
      }
      const { filter } = options as {
        filter: (x: T) => boolean;
      };
      if (filter) {
        return items.find((item) => filter(item));
      }

      return undefined;
    }),
  );
}
function getMany<T>(
  loadableAttributeStore: LoadableAttributeStore<T[]>,
  options: LoadableAttributeCollectionStoreGetOptions<T> = {},
): Observable<T[]> {
  return loadableAttributeStore.get().pipe(
    map((items) => {
      const { criteria } = options as {
        criteria?: Partial<T>;
      };
      if (criteria) {
        return items.filter((item) => identifyByCriteria<T>(criteria, item));
      }
      const { filter } = options as {
        filter: (x: T) => boolean;
      };
      if (filter) {
        return items.filter((item) => filter(item));
      }

      return items;
    }),
  );
}

function setOne<T>({
  value,
  identify,
  loadableAttributeStore,
  actionId,
}: {
  value: T;
  identify: (x: T) => boolean;
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): void {
  reduceOne({
    identify,
    reduceFn: () => value,
    loadableAttributeStore,
    actionId,
    ifNotFound: (items) => items.concat([value]), // add to store if not found
  });
}

function addOrUpdateMany<T>({
  values,
  identify,
  loadableAttributeStore,
  actionId,
}: {
  values: T[];
  identify: (a: T, b: T) => boolean;
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): void {
  loadableAttributeStore.reduce((existingItems: T[]) => {
    const newStoreItems = values.reduce((acc, newValue) => {
      let found = false;
      const newItems = acc.map((x) => {
        const alreadyExists = identify(x, newValue);
        if (alreadyExists) {
          // update
          found = true;
          return newValue;
        }
        return x;
      });

      if (!found) {
        // add
        return acc.concat([newValue]);
      }

      return newItems;
    }, existingItems);

    return newStoreItems;
  }, actionId);
}

function addOne<T>({
  value,
  loadableAttributeStore,
  actionId,
}: {
  value: T;
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): void {
  loadableAttributeStore.reduce((items) => {
    return items ? items.concat([value]) : [value];
  }, actionId);
}

function addMany<T>({
  values,
  loadableAttributeStore,
  actionId,
}: {
  values: T[];
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): void {
  loadableAttributeStore.reduce(
    (items) => (items ? items.concat(values) : [...values]),
    actionId,
  );
}

function reduceOne<T>({
  identify,
  reduceFn,
  ifNotFound,
  loadableAttributeStore,
  actionId,
}: {
  identify: (x: T) => boolean;
  reduceFn: (item: T) => T;
  ifNotFound?: (items: T[]) => T[];
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): T {
  let updated: T;
  let found = false;
  loadableAttributeStore.reduce((items: T[]) => {
    const newItems = items.map((x) => {
      if (identify(x)) {
        found = true;
        updated = reduceFn(x);
        return updated;
      }
      return x;
    });

    if (!found && ifNotFound) {
      return ifNotFound(newItems);
    }

    return newItems;
  }, actionId);

  return updated;
}

function removeOne<T>({
  identify,
  loadableAttributeStore,
  actionId,
}: {
  identify: (x: T) => boolean;
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): T {
  let removed: T;
  loadableAttributeStore.reduce(
    (items: T[]) => items.filter((x) => !identify(x)),
    actionId,
  );

  return removed;
}

// c'est pareil que removeOne, il faudrait peut-être optimiser removeOne?
function removeMany<T>({
  identify,
  loadableAttributeStore,
  actionId,
}: {
  identify: (x: T) => boolean;
  loadableAttributeStore: LoadableAttributeStore<T[]>;
  actionId?: string;
}): T[] {
  const remaining: T[] = loadableAttributeStore.reduce(
    (items: T[]) => items.filter((x) => !identify(x)),
    actionId,
  );

  return remaining;
}

// function load<T>() {
//   return {
//     list: [] as T[],
//     maps:
//   };
// }

function create<T>(
  store: SimpleStore<any>,
  name: string,
): LoadableAttributeCollectionStore<T> {
  // : LoadableAttribute<T[]>

  const initialValue: LoadableAttribute<T[]> = undefined;
  // LoadableAttribute<T> {
  //   isLoaded?: boolean;
  //   value?: T;
  // }

  const loadableAttributeStore = loadableAttributeStoreFactory.create(
    store,
    name,
    initialValue,
  );

  return {
    getSnapshot: () => loadableAttributeStore.getSnapshot(),
    getOne: (options: LoadableAttributeCollectionStoreGetOptions<T>) =>
      getOne<T>({
        loadableAttributeStore,
        options,
      }),
    getAll: () => loadableAttributeStore.get(),
    getMany: (options: LoadableAttributeCollectionStoreGetOptions<T>) =>
      getMany<T>(loadableAttributeStore, options),
    getAllAsMap: (keyAttributeName: keyof T) =>
      loadableAttributeStore
        .get()
        .pipe(
          map((items) => objectMapBuilder.buildMap<T>(items, keyAttributeName)),
        ),
    setAll: (value: T[], actionId?: string) =>
      loadableAttributeStore.set(value, actionId || 'setAll'),
    unload: (actionId?: string) =>
      loadableAttributeStore.unload(actionId || 'unload'),
    removeAll: (actionId?: string) =>
      loadableAttributeStore.set([], actionId || 'remove'),
    addOne: ({ value, actionId }: { value: T; actionId?: string }) =>
      addOne({ value, actionId: actionId || 'addOne', loadableAttributeStore }),
    addMany: ({ values, actionId }: { values: T[]; actionId?: string }) =>
      addMany({
        values,
        actionId: actionId || 'addMany',
        loadableAttributeStore,
      }),
    setOne: ({
      identify,
      value,
      actionId,
    }: {
      identify: (x: T) => boolean;
      value: T;
      actionId?: string;
    }) =>
      setOne({
        identify,
        value,
        actionId: actionId || 'setOne',
        loadableAttributeStore,
      }),
    addOrUpdateMany: ({
      identify,
      values,
      actionId,
    }: {
      identify: (a: T, b: T) => boolean;
      values: T[];
      actionId?: string;
    }) =>
      addOrUpdateMany({
        identify,
        values,
        actionId: actionId || 'addOrUpdateMany',
        loadableAttributeStore,
      }),

    reduceAll: (reduceFn: (state: T[]) => T[], actionId?: string) =>
      loadableAttributeStore.reduce(reduceFn, actionId || 'reduceAll'),
    reduceOne: ({
      identify,
      reduceFn,
      actionId,
      ifNotFound,
    }: {
      identify: (x: T) => boolean;
      reduceFn: (item: T) => T;
      actionId?: string;
      ifNotFound?: (items: T[]) => T[];
    }) =>
      reduceOne({
        identify,
        ifNotFound,
        reduceFn,
        actionId: actionId || 'reduceOne',
        loadableAttributeStore,
      }),
    removeOne: ({
      identify,
      actionId,
    }: {
      identify: (x: T) => boolean;
      actionId?: string;
    }) =>
      removeOne({
        identify,
        actionId: actionId || 'removeOne',
        loadableAttributeStore,
      }),
    removeMany: ({
      identify,
      actionId,
    }: {
      identify: (x: T) => boolean;
      actionId?: string;
    }) =>
      removeMany({
        identify,
        actionId: actionId || 'removeMany',
        loadableAttributeStore,
      }),
  };
}

export const loadableAttributeCollectionStoreFactory = {
  create,
};

function identifyByCriteria<T>(criteria: Partial<T>, item: T): boolean {
  return Object.keys(criteria).reduce((match, attributeName) => {
    if (match) {
      const a1 = (item as any)[attributeName] as any;
      const a2 = (criteria as any)[attributeName] as any;
      return a1 === a2;
    }
    return match;
  }, true as boolean);
}
