/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  EcommerceCategoryGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { AppButton, AppIconsAction } from '../../../../_core/modules/layout';
import { DiveCenterEcommerceHomePageLocalState } from '../../useDiveCenterEcommerceHomePageLocalState.hook';
import { EcommerceProductPreviewCover } from '../03.content';

export const CustomerProductsPage_CategoryProductCoverCard = ({
  category,
  product,
  localState,
  className,
}: {
  category: EcommerceCategoryGql_Customer;
  product: EcommerceProductGql_Customer;
  localState: DiveCenterEcommerceHomePageLocalState;
  className?: string;
}) => {
  const { data, actions } = localState;

  const productArticles = useMemo(() => {
    return data.productArticles.filter((a) => a.productId === product?._id);
  }, [data.productArticles, product?._id]);

  const appBooklet = useMemo(() => {
    return data.booklets.find((x) => x._id === product.bookletId);
  }, [data.booklets, product.bookletId]);

  const appBookletPage = useMemo(() => {
    return data.bookletPages.find((x) => x._id === appBooklet?.rootPageId);
  }, [appBooklet?.rootPageId, data.bookletPages]);

  return (
    <div
      className={clsx(
        'app-card app-card-highlight border-t-xl border-app-primary cursor-pointer',
        className,
      )}
      onClick={(e) => {
        e.stopPropagation();
        actions.selectCategoryProduct({
          categoryId: category._id,
          productId: product._id,
        });
      }}
    >
      {/* <h2 className="text-left text-sm font-bold uppercase p-2 text-app-secondary">
        {product.name}
      </h2> */}
      <EcommerceProductPreviewCover
        className="h-full"
        productArticles={productArticles}
        product={product}
        appBookletPage={appBookletPage}
        clubPublicSettings={data.clubPublicSettings}
      >
        <div className="flex flex-col gap-4">
          <AppButton
            className=""
            color="primary-bg"
            size="normal"
            icon={AppIconsAction.open}
            // onClick={(e) => {
            //   e.stopPropagation();
            //   actions.selectCategory(category._id);
            // }}
          >
            En savoir plus
          </AppButton>
        </div>
      </EcommerceProductPreviewCover>
    </div>
  );
};
