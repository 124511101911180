"use strict";
exports.__esModule = true;
exports.DIVE_SERVICE_ORGANIZATIONS = void 0;
exports.DIVE_SERVICE_ORGANIZATIONS = [
    {
        label: 'FFESSM',
        reference: 'ffessm',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '01'
    },
    {
        label: 'ANMP',
        reference: 'anmp',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '02'
    },
    {
        label: 'PADI',
        reference: 'padi',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '03'
    },
    {
        label: 'SSI',
        reference: 'ssi',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '04'
    },
    {
        label: 'TDI-SDI',
        reference: 'tdi-sdi',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '05'
    },
    {
        label: 'FSGT',
        reference: 'fsgt',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '06'
    },
    {
        label: 'CMAS',
        reference: 'cmas',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '07'
    },
    {
        label: 'ADIP',
        reference: 'adip',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '08'
    },
    {
        label: 'SNMP',
        reference: 'snmp',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '09'
    }, {
        label: 'LIFRAS',
        reference: 'lifras',
        orgGroupReference: 'cmas',
        orgGroupReferencev2: 'french',
        sortReference: '10'
    },
    {
        label: 'pss',
        reference: 'pss',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '12'
    },
    {
        label: 'naui',
        reference: 'naui',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '13'
    },
    {
        label: 'bsac',
        reference: 'bsac',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '14'
    },
    {
        label: 'acuc',
        reference: 'acuc',
        orgGroupReference: 'padi',
        orgGroupReferencev2: 'international',
        sortReference: '15'
    },
    // {
    //   label: 'DAN', => DAN (Divers Alert Network) est une organisation internationale spécialisée dans la sécurité et les services médicaux liés à la plongée. Elle propose plusieurs certifications spécifiques à la plongée : https://www.daneurope.org/fr/training-programmes
    //   reference: 'dan',
    //   orgGroupReference: 'cmas',
    //   orgGroupReferencev2: 'french',
    //   sortReference: '14',
    // },
];
