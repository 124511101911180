/* eslint-disable jsx-a11y/alt-text */
import {
  AppBookletPageGql_Customer,
  ClubPublicSettings,
  EcommerceProductArticleGql_Customer,
  EcommerceProductGql_Customer,
} from '@mabadive/app-common-model';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useAppCurrencyMain } from '../../../../../../pages';
import { AppPriceLabel } from '../../../../../club/modules/_common/ui';

export const EcommerceProductPreviewCover = ({
  clubPublicSettings,
  product,
  productArticles,
  appBookletPage,
  children,
  className,
}: {
  clubPublicSettings: ClubPublicSettings;
  product: Pick<EcommerceProductGql_Customer, 'name' | 'details'>;
  productArticles?: EcommerceProductArticleGql_Customer[];
  appBookletPage: Partial<AppBookletPageGql_Customer>;
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
}) => {
  const appUiTheme = clubPublicSettings?.appUiTheme;
  const mainCurrency = useAppCurrencyMain({ clubPublicSettings });

  const cover = appBookletPage?.mainContent?.cover;

  const pricesBounds = useMemo(() => {
    const unitPrices = productArticles
      .reduce(
        (acc, p) => acc.concat(p.pricing.prices.map((pr) => pr.unitPrice)),
        [] as number[],
      )
      .filter((x) => x > 0);
    if (unitPrices.length > 0) {
      return {
        min: Math.min(...unitPrices),
        max: Math.max(...unitPrices),
      };
    }
    return {
      min: 0,
      max: 0,
    };
  }, [productArticles]);

  return (
    product &&
    cover && (
      <div className={clsx('w-full flex flex-col overflow-y-auto', className)}>
        {cover.image && (
          <img
            className="relative object-center w-full rounded-t-xl"
            width={1000} // TODO à stocker dans l'image
            height={400} // TODO à stocker dans l'image
            src={cover.image.publicUrl}
          />
        )}
        <div className="h-full py-2 px-4 flex-grow flex flex-col gap-2 md:gap-4 xl:gap-6 2xl:gap-8 justify-between">
          <div>
            <div
              className={clsx(
                'text-base font-bold w-full text-left',
                // displayWidth === 'mobile'
                //   ? 'text-md'
                //   : 'text-md lg:text-lg',
              )}
              style={{ color: appUiTheme.title1.textColor }}
            >
              {cover?.title?.toUpperCase()}
            </div>
            {cover?.subTitle && (
              <div
                className={clsx('text-sm font-medium w-full text-left')}
                style={{ color: appUiTheme.title2.textColor }}
              >
                {cover?.subTitle}
              </div>
            )}
            <div className="my-4 text-sm font-normal text-gray-600">
              {productArticles.length > 0 && (
                <span>
                  <b>
                    {productArticles.length} produit
                    {productArticles.length > 1 ? 's' : ''}{' '}
                  </b>
                  {product.details.minAge > 0 && (
                    <span> dès {product.details.minAge} ans</span>
                  )}
                </span>
              )}
              {pricesBounds?.min > 0 && (
                <span className="">
                  {pricesBounds?.min === pricesBounds?.max ? (
                    <span>
                      {' - '}
                      <AppPriceLabel
                        className="font-bold"
                        amount={pricesBounds?.min}
                        mainCurrency={mainCurrency}
                      />
                    </span>
                  ) : (
                    <span>
                      {' '}
                      à partir de{' '}
                      <AppPriceLabel
                        className="font-bold"
                        amount={pricesBounds?.min}
                        mainCurrency={mainCurrency}
                      />
                    </span>
                  )}
                </span>
              )}
            </div>
          </div>
          {children && children}
        </div>
      </div>
    )
  );
};
