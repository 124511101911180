/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ClubSettingsGeneralOnlineBookingSessionDiveModeConfig,
  CustomerEcommerceProductArticleFull,
  EcommerceCategoryGql_Customer,
  OnlineBookingDetailsArticleOrderItem,
  OnlineBookingOrderArticle,
  OnlineBookingParticipant,
} from '@mabadive/app-common-model';
import { uuidGenerator } from '@mabadive/app-common-services';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useRedirect } from 'src/business/_core/data/hooks';
import { OnlineBookingProductConfig } from '../../../../../../../../../mabadive-libs/app-common-libs/app-common-model/src/entities/company/settings/club-settings/general/OnlineBookingProductConfig.type';
import { appLogger } from '../../../../../_core/modules/root/logger';
import { DiveCenterEcommerceHomePageLocalState } from '../../../useDiveCenterEcommerceHomePageLocalState.hook';
import {
  CustomerProductsPage_BookProductPanelFormModel,
  CustomerProductsPage_BookProductPanelFormModel_Article,
  CustomerProductsPage_BookProductPanelFormModel_Item,
} from './_model';

type BookProductStep =
  | 'choose-quantity'
  | 'choose-date'
  | 'confirm-product'
  | 'fill-participants';

export function useCustomerProductsPage_BookProductPanelLocalState({
  parentState,
}: {
  parentState: DiveCenterEcommerceHomePageLocalState;
}) {
  const redirectTo = useRedirect();

  const { state, data, actions } = parentState;

  const category: EcommerceCategoryGql_Customer = useMemo(() => {
    return data.categories.find((x) => x._id === state.viewState?.categoryId);
  }, [data.categories, state.viewState?.categoryId]);

  const articleId = state.viewState.articleId;

  const productArticleFull: CustomerEcommerceProductArticleFull =
    useMemo(() => {
      const productArticleFull = (data.productArticlesFull ?? []).find(
        (x) => x.article?._id === articleId,
      );
      return productArticleFull;
    }, [articleId, data.productArticlesFull]);

  const article = productArticleFull?.article;
  useEffect(() => {
    if (productArticleFull && !productArticleFull?.article) {
      appLogger.warn(
        `[CustomerProductsPage_BookProductPanel] article ${articleId} not found`,
      );
      actions.showCategories();
    }
  }, [
    actions,
    article,
    articleId,
    productArticleFull?.article,
    productArticleFull,
  ]);

  const [currentStep, setCurrentStep] =
    useState<BookProductStep>('choose-quantity');

  const initialFormValue = useMemo(() => {
    const items: CustomerProductsPage_BookProductPanelFormModel_Item[] = (
      article?.pricing?.prices ?? []
    ).map((price) => {
      const item: CustomerProductsPage_BookProductPanelFormModel_Item = {
        price,
        quantity: 0, // price._id === article?.pricing?.defaultPriceId ? 1 : 0,
        participants: [],
      };
      return item;
    });
    const formArticle: CustomerProductsPage_BookProductPanelFormModel_Article =
      {
        _id: undefined, // pas utile ici
        categoryId: state.viewState.categoryId,
        productId: state.viewState.productId,
        articleId: state.viewState.articleId,
        activityDate: undefined,
        items,
        articleDetails: {
          name: article?.name,
          productPackageOfferId: article?.productPackageOfferId,
          diveMode: article?.diveMode,
        },
      };
    const formValue: CustomerProductsPage_BookProductPanelFormModel = {
      article: formArticle,
    };
    return formValue;
  }, [
    article?.diveMode,
    article?.name,
    article?.pricing?.prices,
    article?.productPackageOfferId,
    state.viewState.articleId,
    state.viewState.categoryId,
    state.viewState.productId,
  ]);

  const form = useForm<CustomerProductsPage_BookProductPanelFormModel>({
    defaultValues: initialFormValue,
    // mode: 'onChange',
    // reValidateMode: 'onChange',
  });

  const backToPreviousStep = useCallback(() => {
    switch (currentStep) {
      case 'confirm-product':
        setCurrentStep('fill-participants');
        break;
      case 'fill-participants':
        setCurrentStep('choose-date');
        break;
      case 'choose-date':
        setCurrentStep('choose-quantity');
        break;
    }
  }, [currentStep]);

  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case 'choose-quantity':
        setCurrentStep('choose-date');
        break;
      case 'choose-date':
        setCurrentStep('fill-participants');
        break;
      case 'fill-participants':
        setCurrentStep('confirm-product');
        break;
    }
  }, [currentStep]);

  const confirmOrderArticle = useCallback(() => {
    form.handleSubmit(
      (formValue) => {
        const orderArticle: OnlineBookingOrderArticle = {
          _id: uuidGenerator.random(),
          categoryId: state.viewState?.categoryId,
          productId: state.viewState?.productId,
          articleId: state.viewState?.articleId,
          activityDate: formValue.article.activityDate,
          diveSessionDateTime: formValue.article.diveSessionDateTime,
          diveSessionReference: formValue.article.diveSessionReference,
          items: formValue.article.items
            .filter((x) => x.quantity > 0)
            .map((x) => {
              const item: OnlineBookingDetailsArticleOrderItem = {
                price: x.price,
                quantity: x.quantity,
                participants: x.participants.map((x) => ({
                  diverId: x.diverId,
                })),
              };
              return item;
            }),
          comment: formValue.article.comment,
          articleDetails: {
            name: article?.name,
            productPackageOfferId: article?.productPackageOfferId,
            diveMode: article?.diveMode,
          },
        };
        const bookingParticipants: OnlineBookingParticipant[] =
          formValue.article.items
            .filter((x) => x.quantity > 0)
            .reduce((acc, x) => {
              for (const participant of x.participants) {
                const p: OnlineBookingParticipant = {
                  diver: {
                    _id: participant.diverId,
                    firstName: participant.firstName,
                    lastName: participant.lastName,
                  },
                  comment: undefined, // TODO permettre d'ajouter un commentaire sur le participant
                };
                acc.push(p);
              }

              return acc;
            }, [] as OnlineBookingParticipant[]);
        parentState.actions.confirmOrderArticle({
          orderArticle,
          bookingParticipants,
        });
      },
      (err) => {
        appLogger.info(
          '[CustomerProductsPage_BookProductPanel_ChooseQuantity] invalid form',
          err,
        );
      },
    )();
  }, [
    article?.diveMode,
    article?.name,
    article?.productPackageOfferId,
    form,
    parentState.actions,
    state.viewState?.articleId,
    state.viewState?.categoryId,
    state.viewState?.productId,
  ]);

  const onlineBooking = data?.clubSettings?.general?.onlineBooking;
  const sessionDiveMode = useMemo(() => {
    if (onlineBooking?.sessionConfig?.enabled) {
      const product = productArticleFull?.product;
      const article = productArticleFull?.article;
      if (
        article?.diveMode === 'first-dive' &&
        onlineBooking?.sessionConfig?.firstDive?.enabled
      ) {
        // booking de la session de baptême
        return article?.diveMode;
      }
      if (
        article?.diveMode === 'snorkelingSupervised' &&
        onlineBooking?.sessionConfig?.snorkelingSupervised?.enabled
      ) {
        // booking de la session de randonnée palmée
        return article?.diveMode;
      }
    }
  }, [
    onlineBooking?.sessionConfig?.enabled,
    onlineBooking?.sessionConfig?.firstDive?.enabled,
    onlineBooking?.sessionConfig?.snorkelingSupervised?.enabled,
    productArticleFull?.article,
    productArticleFull?.product,
  ]);

  const sessionDiveModeConfig: ClubSettingsGeneralOnlineBookingSessionDiveModeConfig =
    sessionDiveMode === 'first-dive'
      ? onlineBooking?.sessionConfig?.firstDive
      : sessionDiveMode === 'snorkelingSupervised'
      ? onlineBooking?.sessionConfig?.snorkelingSupervised
      : null;

  // NOTE: pour l'instant, une seule config, mais ça va évoluer (attribut "configGroups" à implémenter)
  const productConfig: OnlineBookingProductConfig =
    onlineBooking?.defaultConfig;

  return {
    state: {
      ...parentState.state,
      form,
      currentStep,
      sessionDiveMode,
      sessionDiveModeConfig,
      productConfig,
    },
    data: {
      ...parentState.data,
      productArticleFull,
      category,
    },
    actions: {
      ...parentState.actions,
      confirmOrderArticle,
      setCurrentStep,
      goToNextStep,
      backToPreviousStep,
    },
  };
}

export type CustomerProductsPage_BookProductPanelLocalState = ReturnType<
  typeof useCustomerProductsPage_BookProductPanelLocalState
>;
