import clsx from 'clsx';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';

import {
  ClubPublicSettings,
  CustomerEcommerceProductArticleFull,
} from '@mabadive/app-common-model';
import { CustomerProductsPage_BookProductPanelFormModel } from '../_model';
import { CustomerProductsPage_BookProductPanel_ChooseQuantityPricesItem } from './CustomerProductsPage_BookProductPanel_ChooseQuantityPricesItem';

export const CustomerProductsPage_BookProductPanel_ChooseQuantityPrices = ({
  productArticleFull,
  form,
  clubPublicSettings, 
  className,
}: {
  productArticleFull: CustomerEcommerceProductArticleFull;
  form: UseFormReturn<
    CustomerProductsPage_BookProductPanelFormModel,
    any,
    undefined
  >;
  clubPublicSettings: ClubPublicSettings;
  className?: string;
}) => {
  const { control, setValue, getValues } = form;

  const [items] = useWatch({
    control: form.control,
    name: ['article.items'],
  });
  return (
    <div className={clsx('grid gap-4', className)}>
      <h2 className="uppercase font-medium text-app-primary">
        {'Tarif et nombre de participants'}
      </h2>
      <div className="w-full grid gap-2">
        {items.map((field, fieldIndex) => {
          return (
            <CustomerProductsPage_BookProductPanel_ChooseQuantityPricesItem
              key={`${field.price?._id}-${fieldIndex}`}
              form={form}
              field={field as any}
              fieldIndex={fieldIndex}
              clubPublicSettings={clubPublicSettings}
              productArticleFull={productArticleFull}
            />
          );
        })}
      </div>
    </div>
  );
};
