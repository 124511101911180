import {
  ClubPublicSettingsCustomerSpaceDisplaySessionConfig,
  DiveSession,
} from '@mabadive/app-common-model';
import { clubDiveSessionThemeBuilder } from '@mabadive/app-common-services';
import React, { useMemo } from 'react';
import { useClubResumePublic } from 'src/business/_core/store';
import { diveSessionTitleBuilder } from '../../services';
import { ClubDiveSessionBoats } from '../components/ClubDiveSessionBoats';
import { ClubDiveSessionNameLight } from './ClubDiveSessionNameLight';

export const ClubDiveSessionHeaderTitle = function ({
  diveSession: session,
  config,
  className = '',
}: {
  config: ClubPublicSettingsCustomerSpaceDisplaySessionConfig;
  diveSession: Pick<
    DiveSession,
    | 'name'
    | 'status'
    | 'special'
    | 'time'
    | 'diveSiteId'
    | 'boatsIds'
    | 'diveTourSession2'
    | 'emojiId'
  >;
  className?: string;
}) {
  const { timeDayPeriod } = useMemo(
    () =>
      clubDiveSessionThemeBuilder.buildSessionAttributes({
        diveSession: session,
      }),
    [session],
  );
  const clubResume = useClubResumePublic();

  const sessionTitle = useMemo(
    () => diveSessionTitleBuilder.build(session),
    [session],
  );

  const hasAnySessionName: boolean =
    session.status !== 'on' ||
    // !!diveSite ||
    !!session?.emojiId ||
    !!sessionTitle ||
    !!session.diveTourSession2;

  const sessionsCount = session.diveTourSession2 ? 2 : 1;

  const boats = useMemo(() => {
    return session.boatsIds
      .map((boatId) => clubResume?.boats.find((b) => b._id === boatId))
      .filter((x) => !!x);
  }, [clubResume?.boats, session.boatsIds]);

  return hasAnySessionName || sessionsCount > 1 || boats.length ? (
    <div
      className={`px-0.5 leading-3 flex flex-wrap gap-x-5 text-xs sm:text-sm ${className}`}
    >
      {config?.showBoat && <ClubDiveSessionBoats diveSession={session} />}

      {sessionsCount > 1 && (
        <span
          className={`font-bold rounded-full py-0.5 px-2 bg-day-period-${timeDayPeriod}-dark text-xs text-white text-center align-middle`}
        >
          x{sessionsCount} <span className="hidden sm:inline">BLOCKS</span>
        </span>
      )}
      {hasAnySessionName && (
        <ClubDiveSessionNameLight
          className={'font-bold'}
          diveSession={session}
          truncateText={true}
        />
      )}
    </div>
  ) : null;
};
