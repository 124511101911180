"use strict";
exports.__esModule = true;
exports.ORG_GROUP_INT_DATA_TRAININGS = void 0;
exports.ORG_GROUP_INT_DATA_TRAININGS = [
    // NOTE: la formation Basic Scuba Diver PADI n'existe plus (mais chez SSI, y'a "Basic Diver")
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'discover',
        specialityType: 'main',
        reference: 'BSD',
        certificationReference: 'BD',
        label: 'Formation Basic Diver',
        maxDepth: 12,
        trainingDivesTotalCount: 3,
        compatibilityGroups: ['12m'],
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'discover',
        specialityType: 'main',
        reference: 'DSD',
        certificationReference: 'DSD',
        label: 'Formation Discover Scuba Diving',
        maxDepth: 12,
        trainingDivesTotalCount: 3,
        compatibilityGroups: ['12m'],
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'discover',
        specialityType: 'main',
        reference: 'TRY',
        certificationReference: 'TRY',
        label: 'Formation Try Scuba',
        maxDepth: 12,
        trainingDivesTotalCount: 3,
        compatibilityGroups: ['12m'],
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'discover',
        specialityType: 'main',
        reference: 'FSD',
        certificationReference: 'SD',
        label: 'Formation Scuba Diver',
        maxDepth: 12,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['12m'],
        defaultAptitude: 'PE12'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'beginner',
        specialityType: 'main',
        reference: 'FOW',
        certificationReference: 'OW',
        label: 'Formation Open Water',
        maxDepth: 18,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['18m'],
        defaultAptitude: 'PE20'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'security',
        specialityType: 'safety',
        reference: 'FRSC',
        label: 'Formation Rescue',
        certificationReference: 'RSC',
        maxDepth: 18,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['18m'],
        defaultAptitude: 'PE20'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FDG',
        label: 'Formation Dive Guide',
        certificationReference: 'DG',
        maxDepth: 18,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['18m'],
        defaultAptitude: 'PE20'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FADV',
        certificationReference: 'ADV',
        label: 'Formation Advanced Open Water',
        maxDepth: 30,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'advanced',
        specialityType: 'main',
        reference: 'FDM',
        certificationReference: 'DM',
        label: 'Formation Dive Master',
        maxDepth: 30,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['30m'],
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'gaz-certification',
        commercialCategoryId: 'specialty',
        specialityType: 'gas_blending',
        reference: 'FENR',
        specialityReference: 'int-enriched-air',
        label: 'Formation Enriched Air (Nitrox) Diver',
        maxDepth: 40,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['40m'],
        nitrox: 'simple',
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'specialty',
        specialityType: 'deep',
        reference: 'FDEEP',
        specialityReference: 'int-deep',
        label: 'Formation Deep Diver',
        maxDepth: 40,
        trainingDivesTotalCount: 5,
        compatibilityGroups: ['40m'],
        defaultAptitude: 'PE40'
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'specialty',
        specialityType: 'specialty',
        reference: 'FSPE',
        // specialityReference: 'int-deep',
        label: 'Formation de spécialité',
        maxDepth: 18,
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['18m'],
        defaultAptitude: undefined
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'tek',
        specialityType: 'technical',
        reference: 'FTEC',
        // specialityReference: 'int-deep',
        label: 'Formation technique',
        maxDepth: 40,
        trainingDivesTotalCount: 2,
        compatibilityGroups: ['40m'],
        defaultAptitude: undefined
    },
    {
        diveMode: 'training',
        trainingType: 'main-certification',
        commercialCategoryId: 'tek',
        specialityType: 'technical',
        reference: 'F-CCR',
        label: 'Formation recycleur',
        maxDepth: 100,
        trainingDivesTotalCount: 20,
        compatibilityGroups: ['100m'],
        defaultAptitude: undefined
    },
];
