"use strict";
exports.__esModule = true;
exports.EQUIPMENT_TYPES = void 0;
exports.EQUIPMENT_TYPES = [
    'wetsuit',
    'jacket',
    'fins',
    'scubaRegulator',
    'mask',
    'flashLight',
    'computer',
    'tank',
    'compass',
    'rebreather',
    'underwaterScooter',
];
