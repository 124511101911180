import { ClubDiverResidentType } from '@mabadive/app-common-model';

export const clubResidentTypeFormatter = {
  format,
};

function format(type: ClubDiverResidentType): string {
  if (!type) {
    return '';
  }
  if (type === 'local') {
    return 'Résident';
  } else {
    return 'Vacancier';
  }
}
